<template>
  <nav-bar></nav-bar>
  <div
    class="btn-group btn-group-lg"
    role="group"
    aria-label="Large button group"
  >
    <button type="button" class="btn btn-outline-primary" @click="nexpage">
      التالية
    </button>
    <button type="button" class="btn btn-outline-primary" @click="prevuspage">
      السابقة
    </button>
  </div>
  <div class="main">
    <div class="card" style="width: 18rem" v-for="add in adds" :key="add.id">
      <img :src="add.image" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">{{ add.title }}</h5>
        <p class="card-text">{{ add.description }}</p>
        <a :href="add.url" class="btn btn-primary">المزيد</a>
      </div>
      <button type="button" class="btn btn-danger" @click="deletedata(add.id)">
        حذف الاعلان
      </button>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-success"
    @click="($event) => $router.push({ name: 'create-add' })"
  >
    اضافة اعلان
  </button>
</template>

<script>
import navBar from "@/components/navBar.vue";
import axios from "axios";
export default {
  components: { navBar },
  data() {
    return {
      adds: [],
      page: 1,
    };
  },
  methods: {
    getdata() {
      axios
        .get(`https://nursing-ho.com/api/ads?page=${this.page}`)
        .then((res) => {
          this.adds = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletedata(id) {
      axios
        .delete("https://nursing-ho.com/api/ads/" + id)
        .then(() => {
          this.getdata();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nexpage() {
      if (this.adds.length >= 10) {
        this.page++;
        this.getdata();
        console.log(this.page);
      }
    },
    prevuspage() {
      if (this.page > 1) {
        this.page--;
        this.getdata();
        console.log(this.page);
      }
    },
  },
  mounted() {
    this.getdata();
  },
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>

<style scoped>
.card {
  margin: 20px;
  direction: rtl;
}
.main {
  position: absolute;
  top: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.btn-success {
  position: fixed;
  bottom: 5%;
  right: 5%;
}
.btn-group {
  position: absolute;
  top: 10%;
  left: 40%;
}
</style>
