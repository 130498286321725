<template>
  <nav-bar></nav-bar>
  <div
    class="btn-group btn-group-lg"
    role="group"
    aria-label="Large button group"
  >
    <button type="button" class="btn btn-outline-primary" @click="nexpage">
      التالية
    </button>
    <button type="button" class="btn btn-outline-primary" @click="prevuspage">
      السابقة
    </button>
  </div>
  <div class="main">
    <div
      class="card"
      style="width: 18rem"
      v-for="servic in services"
      :key="servic.id"
    >
      <img :src="servic.image" class="card-img-top" alt="..." />
      <div class="card-body">
        <h5 class="card-title">{{ servic.title }}</h5>
        <p class="card-text">{{ servic.description }}</p>
        <div class="urls">
          <a
            v-for="url in servic.urls"
            :key="url.name"
            :href="url.data"
            class="btn btn-primary"
            >{{ url.name }}</a
          >
        </div>
      </div>
      <button
        type="button"
        class="btn btn-danger"
        @click="deletedata(servic.id)"
      >
        حذف الخدمة
      </button>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-success"
    @click="$router.push({ name: 'create-serv' })"
  >
    اضافة خدمات
  </button>
</template>

<script>
import navBar from "@/components/navBar.vue";

import axios from "axios";
export default {
  components: { navBar },
  data() {
    return {
      services: [],
      page: 1,
    };
  },
  methods: {
    async getdata() {
      await axios
        .get(`https://nursing-ho.com/api/service?page=${this.page}`)
        .then((res) => {
          this.services = res.data.data;
          console.log(res.data);
        });
    },
    async deletedata(id) {
      await axios
        .delete("https://nursing-ho.com/api/service/" + id)
        .then(() => {
          this.getdata();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nexpage() {
      if (this.services.length >= 10) {
        this.page++;
        this.getdata();
        console.log(this.page);
      }
    },
    prevuspage() {
      if (this.page > 1) {
        this.page--;
        this.getdata();
        console.log(this.page);
      }
    },
  },
  mounted() {
    this.getdata();
  },
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>

<style scoped>
.card {
  margin: 20px;
  direction: rtl;
}
.main {
  position: absolute;
  top: 20%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.btn-success {
  position: fixed;
  bottom: 5%;
  right: 5%;
}
.btn-group {
  position: absolute;
  top: 10%;
  left: 40%;
}
.urls {
  display: flex;
  flex-direction: column;
}
.urls a {
  width: 100px;
  margin: 5px;
}
</style>
