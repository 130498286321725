import { createRouter, createWebHashHistory } from "vue-router";
import addPage from "@/views/addPage.vue";
import logIn from "@/views/logIn.vue";
import empPage from "@/views/empPage.vue";
import servPage from "@/views/servPage.vue";
import reqPage from "@/views/reqPage.vue";
import createAdd from "@/views/createAdd.vue";
import createserv from "@/views/createServ.vue";
const routes = [
  {
    name: "Adds",
    path: "/adds",
    component: addPage,
  },
  {
    name: "login",
    path: "/",
    component: logIn,
  },
  {
    name: "Employes",
    path: "/employe",
    component: empPage,
  },
  {
    name: "Services",
    path: "/services",
    component: servPage,
  },
  {
    name: "Requests",
    path: "/requests",
    component: reqPage,
  },
  {
    name: "create-add",
    path: "/adds/create-add",
    component: createAdd,
  },
  {
    name: "create-serv",
    path: "/services/create-serv",
    component: createserv,
  },
];

const router = createRouter({
  history: createWebHashHistory(), // <--- this is important
  routes,
});

export default router;
