<template>
  <div class="main">
    <navBar />
    <form action="" @submit.prevent="upload">
      <label for="title">ادخل العنوان</label>
      <input type="text" id="title" v-model="newadd.title" required />

      <label for="info">ادخل المعلومات النصية</label>
      <textarea
        name=""
        id="info"
        v-model="newadd.description"
        required
      ></textarea>
      <label for="url">ادخل الرابط</label>
      <input type="text" id="url" v-model="newadd.url" />
      <label for="img">ادخل صورة</label>
      <input id="img" type="file" @change="onFileSelection" required />
      <input type="submit" value="انشاء" />
    </form>
    <div class="card" style="width: 18rem">
      <div class="card-body">
        <img :src="imgurl" class="card-img-top" alt="..." height="270" />
        <h5 class="card-title">{{ newadd.title }}</h5>
        <p class="card-text">{{ newadd.description }}</p>
        <a :href="newadd.url" class="btn btn-primary"> المزيد</a>
      </div>
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar.vue";
import axios from "axios";

export default {
  data() {
    return {
      newadd: {
        title: "",
        description: "",
        url: "",
        image: "",
      },
      imgurl: "",
    };
  },
  components: { navBar },
  methods: {
    onFileSelection(event) {
      this.newadd.image = event.target.files[0] || event.dataTransfer.files[0];
      this.createImage(this.newadd.image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imgurl = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async upload() {
      let formdata = new FormData();
      formdata.append("title", this.newadd.title);
      formdata.append("url", this.newadd.url);
      formdata.append("description", this.newadd.description);
      formdata.append("image", this.newadd.image);
      await axios
        .post("https://nursing-ho.com/api/ads", formdata)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      location.reload();
    },
  },
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>
<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form input {
  margin: 20px;
  border: 1px solid black;
  padding: 5px;
}
form textarea {
  margin: 20px;
  border: 1px solid black;
}
.main {
  position: absolute;
  top: 20%;
  display: flex;
  padding: 30px;
  width: 1000px;
  justify-content: space-around;
  height: fit-content;
}
</style>
