<template>
  <navBar />
  <div class="main">
    <form action="" @submit.prevent="upload">
      <label for="img">ادخل صورة</label>
      <input id="img" type="file" @change="onFileSelection" />
      <label for="title">ادخل العنوان</label>
      <input type="text" id="title" v-model="newserv.title" />

      <label for="info">ادخل المعلومات النصية</label>
      <textarea
        name=""
        id="info"
        v-model="newserv.description"
        required
      ></textarea>
      <!-- ///////////////////////////// -->
      <label for="facebook">facebook</label>
      <input type="text" id="facebook" v-model="newserv.urls[0].data" />

      <label for="address">address</label>
      <input type="text" id="address" v-model="newserv.urls[1].data" />

      <label for="address">email</label>
      <input type="text" id="email" v-model="newserv.urls[2].data" />

      <label for="phone">رقم الهاتف</label>
      <input type="text" id="phone" v-model="newserv.urls[3].data" />
      <!-- //////////////////////// -->
      <input type="submit" value="انشاء" />
    </form>
    <!-- ////////////////////////////////////////////////// -->
    <div class="card-body">
      <img :src="imgurl" class="card-img-top" alt="..." height="400" />
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar.vue";
import axios from "axios";
export default {
  data() {
    return {
      newserv: {
        title: "",
        description: "",
        urls: [
          {
            type: "url",
            data: "",
            name: "facebook",
          },
          {
            type: "text",
            data: "",
            name: "address",
          },
          {
            type: "email",
            data: "",
            name: "email",
          },
          {
            type: "phone",
            data: "",
            name: "الهاتف",
          },
        ],
        image: "",
      },
      imgurl: "",
    };
  },
  components: { navBar },
  methods: {
    onFileSelection(event) {
      this.newserv.image = event.target.files[0] || event.dataTransfer.files[0];
      this.createImage(this.newserv.image);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imgurl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    logFormData(formData) {
      // FormData doesn't have a simple toString() method, so we use FormData.entries()
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
    },

    async upload() {
      let formdata = new FormData();
      formdata.append("title", this.newserv.title);
      ////////////////////////////////////////////
      this.newserv.urls.forEach((url, index) => {
        if (!url.data == "") {
          console.log("str");
          formdata.append(`urls[${index}][name]`, url.name);
          formdata.append(`urls[${index}][data]`, url.data);
          formdata.append(`urls[${index}][type]`, url.type);
        }
      });
      /////////////////////////////////////////////
      // formdata.append("urls[]", JSON.stringify(this.newserv.urls));
      formdata.append("description", this.newserv.description);
      formdata.append("image", this.newserv.image);
      this.logFormData(formdata);
      await axios
        .post("https://nursing-ho.com/api/service", formdata)
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          if (error.response) {
            console.error("Response error:", error.response.data); // The response body from the server
            console.error("Status:", error.response.status); // The HTTP status code
            console.error("Headers:", error.response.headers); // The response headers
          } else if (error.request) {
            // The request was made but no response was received
            console.error("Request error:", error.request); // The request object itself
          } else {
            // Something happened in setting up the request or other unknown issues
            console.error("General error:", error.message); // General error message
          }
          console.error("Full error object:", error); // Full error object for debugging
        });
      location.reload();
    },
  },
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>
<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form input {
  margin: 20px;
  border: 1px solid black;
  padding: 5px;
}
form textarea {
  margin: 20px;
  border: 1px solid black;
}
.main {
  position: absolute;
  top: 20%;
  left: 30%;
  display: flex;
  padding: 30px;
  width: 1000px;
  justify-content: space-between;
}
</style>
