<template>
  <nav class="navbar navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">مجموعة التيناوي الدولية</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasDarkNavbar"
        aria-controls="offcanvasDarkNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end text-bg-dark"
        tabindex="-1"
        id="offcanvasDarkNavbar"
        aria-labelledby="offcanvasDarkNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">المزيد</h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item" @click="go('/requests')">
              <RouterLink to="/" class="nav-link">طلبات التوظيف</RouterLink>
            </li>
            <li class="nav-item" @click="go('/employe')">
              <router-link to="/employe" class="nav-link"
                >قائمة الموظفين</router-link
              >
            </li>
            <li class="nav-item" @click="go('/adds')">
              <RouterLink to="/adds" class="nav-link">الاعلانات</RouterLink>
            </li>
            <li class="nav-item" @click="go('/services')">
              <RouterLink to="/services" class="nav-link">الخدمات</RouterLink>
            </li>
          </ul>
          <button
            class="btn btn-danger"
            @click="logout"
            style="margin-top: 50px"
          >
            تسجيل الخروج
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
    go(link) {
      router.push(link).then(() => {
        window.location.reload();
      });
    },
  },
};
</script>
