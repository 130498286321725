<template>
  <nav-bar></nav-bar>
  <div class="main" style="direction: rtl">
    <div
      class="card"
      style="width: 18rem"
      v-for="emp in employies"
      :key="emp.id"
    >
      <img
        :src="emp.image"
        class="card-img-top"
        alt="..."
        style="max-height: 150px; max-width: 200px"
      />
      <ul class="list-group list-group-flush" style="direction: rtl">
        <li class="list-group-item">الاسم : {{ emp.first_name }}</li>
        <li class="list-group-item">الكنية : {{ emp.last_name }}</li>
        <li class="list-group-item">اسم الاب : {{ emp.father_name }}</li>
        <li class="list-group-item">{{ emp.birth_date }}</li>
        <li class="list-group-item">المدينة : {{ emp.birth_city }}</li>
      </ul>
      <div class="card-body">
        <a :href="emp.certificate" class="card-link" target="_blank">الشهادة</a>
        <a :href="emp.cv" class="card-link" target="_blank">CV</a>
      </div>
      <div class="card-body">
        <button style="background-color: green" @click="accept(emp.id)">
          قبول
        </button>
        <button style="background-color: red" @click="reject(emp.id)">
          رفض
        </button>
      </div>
    </div>
  </div>
  <div
    class="btn-group btn-group-lg"
    role="group"
    aria-label="Large button group"
  >
    <button type="button" class="btn btn-outline-primary" @click="nexpage">
      التالية
    </button>
    <button type="button" class="btn btn-outline-primary" @click="prevuspage">
      السابقة
    </button>
  </div>
</template>
<script>
import navBar from "@/components/navBar.vue";
import axios from "axios";
export default {
  components: { navBar },
  data() {
    return {
      employies: [],
      page: 1,
      status: "",
    };
  },
  methods: {
    async getdata() {
      await axios
        .get(`https://nursing-ho.com/api/employee?page=${this.page}&status=new`)
        .then((res) => {
          if (res.status == 200) {
            this.employies = res.data.data;
            this.getdata();
          }
        });
    },
    nexpage() {
      if (this.employies.length >= 10) {
        this.page++;
        this.getdata();
        console.log(this.page);
        console.log(this.employies.length);
      }
      console.log(this.employies.length);
    },
    prevuspage() {
      if (this.page > 1) {
        this.page--;
        this.getdata();
        console.log(this.page);
      }
    },
    accept(id) {
      this.status = "approved";
      axios
        .put("https://nursing-ho.com/api/employee/" + id, {
          status: this.status,
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reject(id) {
      this.status = "rejected";
      axios
        .put("https://api.tinawiworld.com/api/employee/" + id, {
          status: this.status,
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getdata();
  },
  created() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
  },
};
</script>
<style scoped>
.main {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 17%;
  padding: 20px;
  justify-content: space-around;
}
.card {
  max-height: 470px;
  max-width: 200px;
  overflow: hidden;
  margin: 20px;
}
.card-body {
  display: flex;
  justify-content: space-between;
}
.card-body a {
  max-width: 60px;
}
ul {
  padding: 0;
}
.btn-group {
  position: absolute;
  top: 10%;
  left: 40%;
}
</style>
